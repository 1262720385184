import { RouterState } from 'connected-react-router';
import { Action } from 'redux';
import { ThunkAction as Act, ThunkDispatch as Dispatch } from 'redux-thunk';
import { PageDeal } from '../api/database/models/pageDeal';

export interface CookieObjType {
  userId: number;
  isAdmin: boolean;
  email: string;
  firstName: string;
  lastName: string;
  hasVerifiedEmail: boolean;
}

export interface AllowedTerm extends BannedTerm {}

export interface SiteEventType {
  id: number;
  title: string;
  isLeadEvent: boolean;
  color: string;
  start: string;
  end: string;
  icon: string;
  description?: string;
  link?: string;
  createdAt: string;
  updatedAt: string;
}

export interface BannedTerm {
  id: number;
  term: string;
  type: string;
  exact: boolean;
  createdAt: string;
}

export interface Correction {
  id: number;
  type: string;
  incorrect: string;
  correct: string;
  createdAt: string;
}

export interface GetAvailableDealsParams {
  limit?: number;
  filters?: string[];
  sort?: string;
}

export interface OptionType {
  value: string;
  label: string;
}

export interface SizeMapType {
  size: string;
  lowest: number;
  highest: number;
  numItems: number;
}

interface EmailSubscriptionType {
  id: string;
  name: string;
  created: string;
  updated: string;
  status: string;
}

export interface TrackedUse {
  item?: string;
  value?: string | number;
  type: string;
}

export interface DealFiltersType {
  onlyFavorites: boolean;
  onlyShowNew: boolean;
  onlyCoupons: boolean;
  onlySponsored: boolean;
  onlySubscribeSave: boolean;
  searchFilter: string;
  categoryFilters: string[];
  trending: boolean;
  primeDay: boolean;
  strategyFilter: string;
  underPrice: number | null;
  overPrice: number | null;
  percentOff: number | null;
}

interface DealType {
  id: string;
  ASIN: string;
  amazonCouponId: string;
  dateEntered: number;
  lastChecked: string;
  couponId: number;
  couponUrl: string;
  couponTitle: string;
  couponImage: string;
  couponLastHadItems: string;
  couponPercentageDiscount: number;
  couponFixedDiscount: number;
  couponMaxDiscount: number;
  couponNumberOfProducts: number;
  title: string;
  listPrice: number;
  isSS: boolean;
  isPantry: boolean;
  imageUrl: string;
  notAddon: boolean;
  isAmazonFamily: boolean;
  percentageDiscount: number;
  fixedDiscount: number;
  priceAfterCoupon: number;
  priceSS5: number;
  priceSS15: number;
  priceAM20: number;
  isPopular: boolean;
  maxDiscount: number;
  lowestPossiblePrice: number;
  category: string;
}

interface DBDealType extends DealType {
  totalSold: number | null;
  dealThreshold: number;
  averagePrice: number | null;
  qtyTerm?: string | null;
  brand?: string | null;
  image?: string | null;
}

export interface SSType {
  af: boolean;
  apiLink: string | null;
  ASIN: string;
  category: string | null;
  finalPrice: null | number;
  image: null | string;
  couponUrl?: null | string;
  couponTitle?: null | string;
  couponImage?: null | string;
  imageHalf: null | string;
  imageHeight: null | number;
  imageWidth: null | number;
  title: string;
  couponFixed: null | number;
  couponPercentage: null | number;
  currentPrice: null | number;
  listPrice: null | number;
  ss: boolean;
  isPrimeExclusivePrice: boolean;
  maxSS: null | number;
  qty: null | number;
  qtyTerm: null | string;
  promoFixed: null | number;
  extraDeal: string | null;
  extraDealLink: string | null;
  extraDealPrice: number | null;
  extraDealPriceEach: number | null;
  extraDealNumber: number | null;
}

type DealsType = Array<DealType>;

interface UsedCoupon {
  couponId: string;
  ASIN: string;
}

export interface UserType {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  hasVerifiedEmail: boolean;
  picture?: string | null;
  needsEmailVerification?: boolean;
  isAdmin?: boolean;
  allUsedCoupons: UsedCoupon[];
  dealsIdeasPending?: number;
  createdAt?: string;
  isSuspended: boolean;
  signupIpAddress?: string;
  lastLoginIpAddress?: string;
  signUpUrl?: string;
  hasUsedVariationsTool?: boolean;
}

export type AdminUserType = Pick<
  UserType,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'createdAt'
  | 'hasVerifiedEmail'
  | 'isSuspended'
  | 'signupIpAddress'
  | 'lastLoginIpAddress'
  | 'picture'
  | 'signUpUrl'
  | 'hasUsedVariationsTool'
>;

export interface VariationLookup {
  id: number;
  ASIN: string;
  createdAt: string;
  title: string;
  parentASIN: string;
  image: string;
}

interface DealRailsType {
  [key: string]: DealPostType[];
}

export interface BlogPageSEO {
  title: string;
  description: string;
  image: string | null;
  slug: string;
  ogPath: string;
  dealSummary: DealPostType | null;
  pageDeal: PageDeal | null;
  isEventDeal?: boolean;
  hasNoContent?: boolean;
  imageWidth?: number | null;
  imageHeight?: number | null;
  datePublished?: string;
  dateModified?: string;
  preloadImages?: string[];
}

export interface DealPageSEOType {
  title: string;
  description: string;
  slug: string;
  imageLarge: string | null;
  imageSmall: string | null;
  imageLargeWidth: number | null;
  imageLargeHeight: number | null;
  collageImage: string | null;
  collageImageSmall: string | null;
  collageImageSmallWidth: number | null;
  collageImageSmallHeight: number | null;
  seoImage: string | null;
  deals?: DealPostType[];
}

// Reducers
export interface HomeState {
  err: any;
  deals: DealsType;
  dealSummary: DealPostType | null;
  dealSummaryError: boolean;
  dealSummaryASIN: string | null;
  withoutKeywordFilterLength?: number;
  displayItemsCount?: number;
  mostPopular: DealPostType[];
  dealRails: DealRailsType;
  primeDayDeals: DealListType[];
  dealSubmissions: UserSubmittedDealType[];
  receiptDeal: null | DealPostType;
  userSuggestionError: string | null;
  dealPageTitle: string;
  slug: string | null;
  ogPath: string;
  ogImage: null | string;
  ogTitle: string;
  dealPageSEO: DealPageSEOType | null;
  couponPageSEO: CouponDetail | null;
  blogPageSEO: BlogPageSEO | null;
  pageDealSlugsSEO: PageDeal[];
}

export type SizeOptions = Array<{
  value: string;
  label: string;
}>;

export interface VariantInfoMapType {
  sizes: string[];
  sizeMaps: SizeMapType[];
  // this is an array see type above
  sizeOptions: SizeOptions;
}

interface VariationsState {
  URL: string;
  isValid: boolean;
  hasVariations: boolean;
  variations: Array<VariationType>;
  loadedVariations: Array<VariationType>;
  errorMessage: string | null;
  hasASIN: boolean;
  ASIN: string;
  noVariationsWithProduct: boolean;
  somethingWentWrong: boolean;
  isLoading: boolean;
  sizeFilterOptions: string[];
  sizeMaps: SizeMapType[];
  sizeFilter: {
    value: string;
    label: string;
  };
  trustHolidayAPIPrices: boolean;
  isHoliday: boolean;
}

export interface MakeListsState {
  lists: ListPreviews;
  currentMode: 'edit' | 'list' | 'new' | 'view';
  currentList: List | null;
  currentId: null | number;
  currentSlug: null | string;
}

export interface List {
  title: string;
  id?: number;
  date: string;
  isLive: boolean;
  tagline: string;
  tags: Array<string>;
  slug: string;
  image: string;
  imageWidth: number | null;
  imageHeight: number | null;
  description: string;
  publishTime: string;
  modifiedTime: string;
  listItems: Array<ListItem>;
}

export interface ListItem {
  listItemId?: number | null | string;
  title: string;
  description: string;
  asin: string;
  store: string;
  image: string;
  imageWebp: string | null;
  brand: string | null;
}

export interface Metric {
  name: string;
  data: number | null;
  actionLink?: string;
  actionLocalLink?: string;
  noHighlight?: boolean;
}

export interface DealFeatureType {
  id: number;

  ASIN: string;

  feature: string;
}

export interface DashboardMetrics {
  newDeals?: Array<DBDealType>;
}

export interface DealsIdeasType {
  id: number;
  ASIN: string;
  image: string;
  title: string;
  finalPrice: number | null;
  final_price: number | null;
  listPrice: number | null;
  list_price: number | null;
  lastUpdated: string;
  createdAt: string;
  source: null | string;
  totalSold: number | null;
  avgPrice: number | null;
  recommendation: null | string;
  created_at: string;
  coupon_fixed: number | null;
  coupon_percentage: number | null;
  averagePriceSold: number | null;
  brand: string | null;
}
export interface PageDealType {
  id: number;
  title: string;
  rawTitle?: string;
  slug: string;
  description: string;
  dateActive: string;
  postDate: string;
  type: string;
  onlySubscribeSave: boolean;
  onlyCoupons: boolean;
  imageLarge: string | null;
  imageLargeWidth: number | null;
  imageLargeHeight: number | null;
  imageSmall: string | null;
  showCategoryFilter: boolean;
  showBrandFilter: boolean;
  strategyId?: string;
  showSort: boolean;
  showSearch: boolean;
  category: string | null;
  menuTitle: string | null;
  tag: string | null;
  dealListId: number | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  showSecondaryCategoryFilter: boolean;
  activeTimeFrame:
    | 'ALL'
    | 'HALLOWEEN'
    | 'CHRISTMAS'
    | 'PRIME_DAY'
    | 'BLACK_FRIDAY'
    | 'CYBER_MONDAY'
    | 'EASTER'
    | 'BACK_TO_SCHOOL';
  brand: string | null;
  keyword: string | null;
  mailingListSignUpUrl: string | null;
  numLiveDeals: number;
  totalSold?: number;
  collageImage: string | null;
  collageImageSmall: string | null;
  collageImageSmallWidth: number | null;
  collageImageSmallHeight: number | null;
  seoImage: string | null;
  socialImage: string | null;
  googleImpressions?: number;
  googleClicks?: number;
  lastCheckSEODate?: string;
  totalBounty?: number;
  totalSalesItemProfit?: number;
  totalProfit?: number;
}

export interface SuggestionType {
  title: string;
  url: string;
  image?: string;
  ASIN?: string;
  isAdmin?: boolean;
  slug?: string;
  description?: string;
}

export interface SpecialOfferType {
  id: number;
  title: string;
  description: string;
  link: string;
  isActive: boolean;
  image?: string;
  imageHeight?: number;
  imageWidth?: number;
  expires?: string;
  priority?: number;
  discountPercent?: number;
  discountX?: number;
  discountOffY?: number;
  promoCode?: string;
  createdAt: string;
  updatedAt: Date;
}

export interface FeedObjectType {
  dealObj: DealPostType | GiftCardType[] | PageDeal | string;
  dealObjType: string;
  postDate: string;
  title: string;
  permalink: string;
  brand?: string;
  isMainContent?: boolean;
  relatedDeals?: DealPostType[];
}

export interface ScheduledEmailType {
  id: number;
  scheduleTime: string;
  meta: null | {
    [key: string]: any;
  };
  sendToList: string;
  slug: string | null;
  ASIN: string | null;
  promoCode: string | null;
  priceThreshold: number | null;
  dealNumThreshold: number | null;
}

export interface CouponDetail {
  name: string;
  promoCode: string;
  numLiveDeals: number;
  couponFixed: number | null;
  couponPercentage: number | null;
  image: string | null;
  imageHeight: number | null;
  imageWidth: number | null;
  createdAt: string;
}

export interface BrowseNodeEntryType {
  Id: string;
  ContextFreeName: string;
  DisplayName: string;
  IsRoot: boolean;
}

export interface OCRImageData {
  image: string;
  ASIN: string;
  text: string;
  isBanned: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface DealData {
  ASIN: string;
  price_now: number;
  price_lowest: number;
  before_price: number;
  last_updated: string;
  image_link: string;
  image_link_width: number;
  image_link_height: number;
  title: string | null;
  link: string;
  parent_asin: string | null;
  brand: string | null;
  category: string | null;
}

export interface PageDealItemType {
  PageDeal: PageDealType;
  createdAt: string;
}

export interface DealPostType {
  postDate: string;
  postTitle: string | null;
  finalPrice: number;
  ss: number | null;
  promoFixed: number | null;
  couponFixed: number | null;
  couponPercentage: number | null;
  promoCode?: string | null;
  extraDealPrice: number | null;
  extraDealPriceEach: number | null;
  extraDeal: string | null;
  extraDealLink: string | null;
  qtyTerm: string | null;
  qty: number | null;
  listPrice: number | null;
  qtyLimit: number | null;
  priceAfterCoupon: number | null;
  currentPrice: number | null;
  parentASIN: string | null;
  ASIN: string | null;
  title: string | null;
  image: string | null;
  imageHeight?: number | null;
  imageWidth?: number | null;
  category: string | null;
  maxSs: number | null;
  averagePrice?: number | null;
  totalSold?: number;
  totalSalesItems?: number;
  dealThreshold?: number;
  lastEmailed?: string | null;
  dateAdded?: string | null;
  dateActive?: string | null;
  reviewStars?: number;
  lastChecked?: string | null;
  listingMode?: string | null;
  highResImage?: string | null;
  highResImages?: string | null;
  highResImagesInformation?: {
    image: string;
    width: number;
    height: number;
  }[];
  DealFeatures?: DealFeatureType[];
  quant?: number | null;
  brand?: string | null;
  dateFirstActive?: string | null;
  DealListItems?: Array<{
    listId: number;
    updatedAt: string;
    createdAt: string;
    ASIN: string;
    DealList: DealList;
  }>;
  postText?: string | null;
  dateLastSold?: string | null;
  compareTo?: string | null;
  compareImage?: string | null;
  compareImageWidth?: number | null;
  compareImageHeight?: number | null;
  comparePrice?: number | null;
  slug?: string | null;
  submittedBy?: string | null;
  isExpired?: boolean;
  PageDeals?: PageDealType[];
  PageDealItems?: PageDealItemType[];
  submittedByUserId?: number | null;
  isBestSeller?: boolean;
  sizeTerm?: string | null;
  s?: boolean;
  sEnds?: string | null;
  sLink?: string | null;
  image1500Url?: string | null;
  image1500Height?: number | null;
  image1500Width?: number | null;
  image600Url?: string | null;
  image600Height?: number | null;
  image600Width?: number | null;
  image240Url?: string | null;
  image240Height?: number | null;
  image240Width?: number | null;
  image100Url?: string | null;
  image100Height?: number | null;
  image100Width?: number | null;
  isLive?: boolean;
  finalPriceOverride?: number | null;
  secondCategory?: string | null;
  strategyId?: string | null;
  socialBought?: string | null;
}

export interface SentMailingType {
  id: number;
  createdAt: string;
  ASIN?: string;
  slug?: string;
  subject: string;
  thumbnailUrl: string;
  sentToList: string;
  singleSendId: string;
  opens: number;
  clicks: number;
  uniqueOpens: number;
  spamReports: number;
  unsubscribes: number;
  dropped: number;
}

export interface DiaperDealType {
  ASIN: string;
  title: string;
  qty: number;
  qtyTerm: string;
  likelySize: string;
}

export interface ContentFetchingOptions {
  isLoggedIn?: boolean;
  isVerifiedUser?: boolean;
  skipJSONTransForm?: boolean;
  slug?: string;
  dealSchema?: any;
  userDealSchema?: any;
  // whether to limit the attributes fetched for the frontend
  limitPayload?: boolean;
}

export interface UserSubmittedDealType {
  id: number;
  ASIN: string;
  dealThreshold: number;
  userId: number;
  status: string;
  statusReason: null | string;
  scannedPrice: null | string;
  comment: null | string;
  createdAt: string;
  updatedAt: string;
  User: Pick<UserType, 'firstName' | 'lastName' | 'id' | 'email'>;
}

export type CSVRowsType = object[][];

interface AdminState {
  reviewSubmissions?: UserSubmittedDealType[];
}

export interface AppState {
  home: HomeState;
  variations: VariationsState;
  lists: MakeListsState;
  router: RouterState;
  admin: AdminState;
}

// Actions

const USERS_REQUESTING = 'USERS_REQUESTING';
const USERS_SUCCESS = 'USERS_SUCCESS';
const USERS_FAILURE = 'USERS_FAILURE';

interface UsersAction {
  type: typeof USERS_REQUESTING | typeof USERS_SUCCESS | typeof USERS_FAILURE;
  data?: Array<object>;
  err?: any;
}

const USER_REQUESTING = 'USER_REQUESTING';
const USER_SUCCESS = 'USER_SUCCESS';
const USER_FAILURE = 'USER_FAILURE';

interface UserAction {
  type: typeof USER_REQUESTING | typeof USER_SUCCESS | typeof USER_FAILURE;
  userId: string;
  data?: object;
  err?: any;
}

export const LISTS_CHANGE_VARIABLE = 'LISTS_CHANGE_VARIABLE';
const LISTS_SAVE_LIST = 'LISTS_SAVE_LIST';
const LISTS_DELETE_LIST = 'LISTS_DELETE_LIST';
export const LISTS_NEW_LIST = 'LISTS_NEW_LIST';
const LISTS_FETCH_LISTS = 'LISTS_FETCH_LISTS';
export const LISTS_FETCHED_LISTS = 'LISTS_FETCHED_LISTS';
export const LISTS_LOADED_LIST = 'LISTS_LOADED_LIST';
export const LISTS_BACK_TO_LISTS = 'LISTS_BACK_TO_LISTS';
export const LISTS_UPDATE_LIST = 'LISTS_UPDATE_LIST';
export const LIST_VIEW_LOADED = 'LIST_VIEW_LOADED';
export const LIST_VIEW_SET_CURRENT_SLUG = 'LIST_VIEW_SET_CURRENT_SLUG';

type ListsType = Array<List>;

export interface ListPreview {
  id: number;
  title: string;
  slug: string;
  image?: string;
  isLive: boolean;
}

export type ListPreviews = Array<ListPreview>;

export interface ListAction {
  type:
    | typeof LISTS_CHANGE_VARIABLE
    | typeof LISTS_SAVE_LIST
    | typeof LISTS_DELETE_LIST
    | typeof LISTS_NEW_LIST
    | typeof LISTS_UPDATE_LIST
    | typeof LIST_VIEW_LOADED
    | typeof LIST_VIEW_SET_CURRENT_SLUG
    | typeof LISTS_FETCHED_LISTS
    | typeof LISTS_LOADED_LIST
    | typeof LISTS_BACK_TO_LISTS
    | typeof LISTS_FETCH_LISTS;
  variable?: string;
  listId?: number;
  currentSlug?: string | null;
  lists?: ListsType;
  list?: List;
  listPreviews?: ListPreviews;
  data?: any;
  err?: any;
}

interface VariantsType {
  // eslint-disable-next-line camelcase
  color_name?: string;
  // eslint-disable-next-line camelcase
  size_name?: string;
}

export interface VariationDealDbType {
  finalPrice?: number;
  couponFixed?: number;
  couponPercent?: number;
  promoFixed?: number;
  ss?: boolean;
  maxSs?: number;
  ASIN?: string;
  slug?: string;
}

export interface VariationType {
  ASIN: string;
  title: string;
  price: string;
  amount: number;
  listPrice?: number;
  listPriceAmount?: number;
  image?: string;
  url: string;
  prime?: number;
  variants?: VariantsType;
  parentASIN?: string;
  hasDealInDb?: VariationDealDbType;
}

export interface PromotionType {
  extraDealLink: string;
  extraDealName?: string;
  promotionName?: string;
  dateCreated?: string;
  hidePromotion: boolean;
  numLiveDeals?: number;
}

export type PromotionsType = PromotionType[];

export interface DealLog {
  id: number;
  ASIN: string;
  message: string;
  meta: string;
  createdAt: string;
  updatedAt: string;
}

export interface PromotionDisplayType {
  extraDealLink: string;
  extraDealName?: string;
  promotionName?: string;
  hidePromotion: boolean;
  dateCreated: string;
  imageWebp500: string;
  image500: string;
  image248?: string;
  image248Width?: number;
  image248Height?: number;
  id: number;
  numLiveDeals: number;
}

export interface GiftCardType {
  id: number;
  title: string;
  image: string;
  link: string;
  // eslint-disable-next-line camelcase
  date_added: string;
  ASIN: string | null;
  totalSold: number | null;
}

export interface PendingDealsIdea {
  id: number;
  URL: string;
  ASIN: string;
  lastUpdated: string | null;
  createdAt: string;
  source: string;
}

export interface CategoryType {
  category: string;
  numLiveDeals: number;
}

export interface ToyDeal {
  ASIN: string;
  priceLowest: number;
  priceNow: number;
  couponFixed: number;
  beforePrice: number;
  title: string;
  lastUpdated: string;
  doNotShowAbove: number;
  totalSold: number;
  averagePriceSold: number;
  lastGood: string;
  imageLink: string;
  firstEntered: string;
  couponPercent: number;
  isInDeals: number | null;
  isLive: boolean;
}

export interface PopularVariation {
  image: string;
  brand: string;
  parentASIN: string;
  image100Url: string;
}

export interface VariationSearched {
  id: number;
  datetime: string;
  ASIN: string;
  IP: string;
  title: string;
  parentASIN: string;
  results: number;
  lowestPrimePrice: number;
  lowestPrimePriceListPrice: number;
  lowestPrimeImage?: string;
  isFromCache?: number;
  isDirect?: number;
  isBot?: number;
  userId?: number | null;
}

export interface TrackUseDataType {
  id: number;
  type: string;
  value: string;
  item: string;
  ip: string;
  datetime: string;
  userId: number | null;
  isBot: boolean;
}

export interface SalesChartPoint {
  date: string;
  tag: string;
  qty: number;
  revenue: number;
}

export interface ImageInfo {
  width: number;
  height: number;
  image: string;
}

export interface ImageSegmentsType {
  [key: string]: ImageInfo[][];
}

export interface PopularBrand {
  brand: string;
  count: number;
}

export interface FavoriteDealType {
  Deal: Pick<DealPostType, 'ASIN' | 'title' | 'image'>;
  createdAt: string;
}

export interface SubSType {
  [key: string]: string;
}

export interface CommissionType {
  date: string;
  totalCommission: number;
  totalQty: string;
  totalRevenue: number;
}

export const DEALS_GET_DEAL_404 = 'DEALS_GET_DEAL_404';
export const DEALS_UPDATE_RECEIPT_DEAL = 'DEALS_UPDATE_RECEIPT_DEAL';
export const DEALS_GET_DEAL_SUMMARY = 'DEALS_GET_DEAL_SUMMARY';
export const DEALS_LOADING_DEAL_SUMMARY = 'DEALS_LOADING_DEAL_SUMMARY';
export const DEALS_UPDATE_DEAL_PAGE_SEO = 'DEALS_UPDATE_DEAL_PAGE_SEO';
export const DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO =
  'DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO';
export const DEALS_UPDATE_BLOG_PAGE_SEO = 'DEALS_UPDATE_BLOG_PAGE_SEO';
export const DEALS_UPDATE_COUPON_PAGE_SEO = 'DEALS_UPDATE_COUPON_PAGE_SEO';
export const DEALS_UPDATE_MOST_POPULAR_DEALS =
  'DEALS_UPDATE_MOST_POPULAR_DEALS';
const DEALS_UPDATE_MOST_POPULAR_COUPON_DEALS =
  'DEALS_UPDATE_MOST_POPULAR_COUPON_DEALS';
export const DEALS_UPDATE_DEAL_RAIL = 'DEALS_UPDATE_DEAL_RAIL';

export interface DealsAction {
  type:
    | typeof DEALS_LOADING_DEAL_SUMMARY
    | typeof DEALS_UPDATE_RECEIPT_DEAL
    | typeof DEALS_GET_DEAL_SUMMARY
    | typeof DEALS_GET_DEAL_404
    | typeof DEALS_UPDATE_MOST_POPULAR_COUPON_DEALS
    | typeof DEALS_UPDATE_MOST_POPULAR_DEALS
    | typeof DEALS_UPDATE_DEAL_PAGE_SEO
    | typeof DEALS_UPDATE_BLOG_PAGE_SEO
    | typeof DEALS_UPDATE_COUPON_PAGE_SEO
    | typeof DEALS_UPDATE_PAGE_DEAL_SLUGS_SEO
    | typeof DEALS_UPDATE_DEAL_RAIL;
  deals?: DealsType;
  mostPopular?: DealPostType[];
  dealRail?: DealPostType[];
  dealRailKey?: string;
  user?: UserType;
  err?: DealsType;
  dealsIdeasPending?: number;
  params?: any;
  receiptDeal?: DealPostType | null;
  primeDayDeals?: DealListType[];
  hiddenASIN?: string;
  emailSubscriptions?: EmailSubscriptionType[];
  dealSummary?: DealPostType;
  dealSummaryASIN?: string;
  ogTitle?: string;
  ogPath?: string;
  dealPageTitle?: string;
  ogImage?: string;
  slug?: string | null;
  couponPageSEO?: CouponDetail;
  dealPageSEO?: {
    title: string;
    description: string;
  } | null;
  blogPageSEO?: BlogPageSEO | null;
  pageDealSlugsSEO?: PageDeal[];
}

export type DealListType = {
  dealListName?: string;
  sectionName?: string;
  dealListItems: DealPostType[];
  isMisc?: boolean;
};

export interface DealListSection {
  id: number;
  name: string;
  isWeekly: boolean;
  description: string | null;
}

export interface DealList {
  id: number;
  name: string;
  isWeekly: boolean;
  sectionId: number;
  description: string | null;
  numLiveDeals: number;
  activeTimeFrame: string;
  isActive: boolean;
}

export interface DealListItem {
  id: number;
  ASIN: string;
  listId: number;
  description: string | null;
  isLive: boolean;
}

export type MyAction = UsersAction | UserAction | DealsAction | ListAction;

export type ThunkAction = Act<void, AppState, null, Action<string>>;

export type ThunkDispatch = Dispatch<AppState, void, MyAction>;
